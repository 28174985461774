import {routerRedux} from "dva/router";
// import {Base64} from 'js-base64';
import qs from 'qs';
import {createIntl} from 'react-intl';

import CommonUtil from "../utils/common-util";
import StorageUtil from "../utils/storage-util";
import {LocalConst, SessionConst} from "../utils/constant";
import InitUtil from "../utils/init-util";
// import UrlUtil from "../utils/url-config";
import {ShopFetch, SystemFetch} from "../services/service";
// import CheckUtil from "../utils/check-util";

import zh_CN from "../locale/zh_CN"; // 中文
import es_Es from "../locale/es_ES" // 西班牙语
import en_Us from "../locale/en_US" // 西班牙语
// import { message } from "antd";
// 多语言
const messages = {
    'zh': zh_CN,
    'es': es_Es,
    'en': en_Us
};
const defaultIntl = createIntl({
    locale: 'zh',
    messages: messages['zh']
});

export default {
    namespace: 'appModel',
    state: {
        typeList: [],
        brandList: [],
        tagList: [],
        propertyList: [],
        homeFloors: [],
        pageHeader: {},
        pageFooter: {},
        broadside_: {},
        decorateInfo: {},
        lang: StorageUtil.getItem(LocalConst.lang) || 'zh',
        intl: defaultIntl,//多语言
        isShowNotice: false, // 是否显示通知
    },

    subscriptions: {
        // 初始化数据
        init({dispatch, history}) {
            // const {location} = history;
            // let query = CommonUtil.getSearchFromUrl();
            // let companyId = StorageUtil.getItem(LocalConst.companyId);
            // const host = window.location.host;
            // TODO: 临时处理遮罩层
            CommonUtil.setTheme();
            let useInfo = StorageUtil.getItem(LocalConst.mShop_user);
            const currentPathName = window.location.pathname;
            let pathname = `/login`
            
            if (useInfo) {
                const company_id_ = useInfo.company_id_;
                pathname = currentPathName.includes('login') ?  `/home/${company_id_}` : currentPathName;
                dispatch({type: 'getCompany', payload: {companyId: company_id_}}).then(() => {
                    // TODO: 临时处理 防止页面刷新跳转至home页面
                    dispatch(routerRedux.replace({pathname, search: `${window.location.search.slice(1)}`}));
                });
            } else  if (!currentPathName.includes('/page')) {// 不需要重新跳转 预览页面可以不登陆
                dispatch(routerRedux.replace({pathname}));
            }
            // dispatch(routerRedux.replace({ pathname }));
            return false;
            // 获取登陆用户信息
            // let ysUser = StorageUtil.getItem(LocalConst.mShop_user);
            // const companyTheme = StorageUtil.getItem(LocalConst.companyTheme);
            // if (host.indexOf('store') > -1 || host.indexOf('astore') > -1) {
            //     companyId = parseInt(host.replace('astore', '')
            //         .replace('store', ''));
            //     if (companyId) {
            //         //根据store后面企业id获取信息
            //         dispatch({type: 'getCompanyById', payload: {companyId: companyId}});
            //     } else {
            //         //自定义域名
            //         dispatch({type: 'getByHost'});
            //     }
            // }
            // //不同公司id,清除本地缓存
            // if (query.companyId && (+query.companyId !== companyId)) {
            //     localStorage.clear();
            //     companyId = +query.companyId;
            //     // 更新企业id
            //     StorageUtil.setItem(LocalConst.companyId, +query.companyId);
            // }
            // const numReg = /^[\d]+$/ig;
            // if (query.auth_code_ && numReg.test(query.auth_code_)) {
            //     // 调用登陆接口
            //     SystemFetch.getLoginUserByAuthCode({ authCode: query.auth_code_ })
            //         .then((data) => {
            //             ysUser = data.data;
            //             companyId = ysUser.company_id_;
            //             // 本地保存登录信息
            //             CommonUtil.saveStorageFormLogin(ysUser);
            //             dispatch(routerRedux.replace({
            //                 pathname: `${location.pathname}${query.id ? `/${query.id}` : ''}`,
            //                 search: `companyId=${companyId}`
            //             }));
            //            window.location.reload();
            //         })

            // } else if (query.auth_code_) {
            //     // webapp登陆跳转
            //     const auth_code_ = JSON.parse(Base64.decode(decodeURIComponent(query.auth_code_)));
            //     ysUser = auth_code_;
            //     // 本地保存登录信息
            //     CommonUtil.saveStorageFormLogin(auth_code_);
            //     dispatch(routerRedux.replace({
            //         pathname: `${location.pathname}${query.id ? `/${query.id}` : ''}`,
            //         search: `companyId=${companyId}`
            //     }));
            // }
            // // 分享人
            // if (query.u) {
            //     StorageUtil.setItem(LocalConst.shareUserId, query.u, true)
            // }
            // //分享客户
            // if (query.c) {
            //     StorageUtil.setItem(LocalConst.shareClientId, query.c, true)
            // }

            // // 设置主题色
            // if (companyTheme || (ysUser && ysUser.company_theme_)) {
            //     CommonUtil.setTheme(companyTheme || (ysUser && ysUser.company_theme_));
            // }
            // 不存在companyId，跳转登陆
            // if (!companyId) {
            //     dispatch({type: 'getByHost'});
            //     return;
            //     // Toast.info(`缺少企业id,${window.location.href}`);
            // }
            // dispatch({type: 'getCompany', payload: {companyId: companyId}});

        },

        listen({history, dispatch}) {
            // 监听 history 变化，记录上一个路由
            return history.listen((location) => {
                const query = qs.parse(location.search, {ignoreQueryPrefix: true});
                const companyId = StorageUtil.getItem(LocalConst.companyId);
                const localHistory = StorageUtil.getItem(SessionConst.localHistory, true) || [];
                const historyLen = localHistory.length;
                // 两次路由相同 splice
                if (historyLen > 0 && location.pathname === localHistory[historyLen - 1].pathname) {
                    localHistory.splice(historyLen - 1, 1, location)
                } else {
                    localHistory.push(location);
                }
                if (historyLen > 12) {
                    localHistory.shift()
                }
                StorageUtil.setItem(SessionConst.localHistory, localHistory, true);
                if (!query.companyId && companyId) {
                    query.companyId = companyId;
                    location.search = qs.stringify(query);
                    // history.replace(location);
                }
            });
        },
        setup({history}) {
            return history.listen(({ pathname }) => {
                if (!pathname.includes('/order/detail') && !pathname.includes('/order/list')) {
                    console.log('0000 pathname', pathname)
                    sessionStorage.removeItem('$order_page');
                }
            })
        }
    },

    effects: {
        // 根据域名获取企业信息
        * getByHost({payload}, {call, put}) {
            const rs = yield call(SystemFetch.getByHost);
            // if (!rs.data || !rs.status) return;
            yield put({type: 'getCompany', payload: {companyId: rs.data ? rs.data.id_ : undefined}});
        },
        * getCompany({payload}, {call, put, select}) {
            const companyId = payload.companyId;
            let query = CommonUtil.getSearchFromUrl();
            // const host = window.location.host;
            console.log('getCompany', companyId, query);
            //手机打开PC链接重定向至H5页面
            // if (CheckUtil.isMobile) {
            //     let goodsId = '';
            //     let url = '';
            //     if (window.location.pathname.indexOf('/goods/detail/') > -1) {
            //         const hash = window.location.pathname.split('/');
            //         goodsId = hash[hash.length - 1];
            //     }

            //     url = UrlUtil.getMShopUrl() + '/shop?companyId=' + (companyId || query.companyId);
            //     if (goodsId) {
            //         url = UrlUtil.getMShopUrl() + '/shop/GoodsDetail?companyId=' + (companyId || query.companyId) + '&id=' + goodsId;
            //     }
            //     window.location.href = url;
            //     return
            // }
            // 获取登陆用户信息
            let ysUser = StorageUtil.getItem(LocalConst.mShop_user);
            

            yield put({type: 'getPageDataByWeb', payload: companyId});

            const companyRs = yield call(ShopFetch.getCompany, companyId);

            StorageUtil.setItem(LocalConst.companyName, companyRs.data.name_);
            StorageUtil.setItem(LocalConst.companyTheme, companyRs.data.theme_);
            // document.title = companyRs.data.shop_home_title_ || companyRs.data.name_;
            // 使用固定tite
            document.title = 'Aqara商城';

            //设置主题色
            CommonUtil.setTheme(companyRs.data.theme_);
            //设置浏览器小icon
            // CommonUtil.setIco(companyRs.data.mini_web_icon_);
            // 创建intl 用于model
            const intl = createIntl({
                locale: companyRs.data.language_type_,
                messages: messages[companyRs.data.language_type_]
            });
            yield put({
                type: 'saveState',
                payload: {
                    lang: companyRs.data.language_type_,
                    currencySymbol: companyRs.data.currency_type_ || "¥",
                    intl: intl
                }
            });
            yield put({type: 'getGoodsBaseData', payload: {companyId: companyRs.data.id_}});
            // 获取公司详细信息
            InitUtil.getCompany(companyId).then(rs => {
                if (rs.status === 1) {
                    // 当前语言
                    StorageUtil.setItem(LocalConst.lang, rs.data.language_type_);
                    // 商城货币类型
                    StorageUtil.setItem(LocalConst.currencySymbol, rs.data.currency_type_ || "¥");

                    StorageUtil.setItem(LocalConst.companyName, rs.data.name_);
                    StorageUtil.setItem(LocalConst.companyTheme, rs.data.theme_);
                    document.title = 'Aqara商城';
                    //设置主题色
                    CommonUtil.setTheme(rs.data.theme_);
                    //设置浏览器小icon
                    // CommonUtil.setIco(rs.data.mini_web_icon_);
                    // 创建统计代码
                    CommonUtil.setStatisticalCode(rs.data.view_count_url_);

                }
            });
            // 获取企业应用
            InitUtil.getApplicationStatus(companyId);
            // 商城设置信息
            InitUtil.getShopCompanySetting(companyId);
            // 注册设置
            InitUtil.getShopRegisterSetting(companyId);
            //初始化获取省市县
            InitUtil.buildSimpleRegionTree();
            // 获取购物车数量
            if (ysUser) {
                InitUtil.getUnReadNoticeNumber();
                InitUtil.getCartNumber();
                InitUtil.getClientInfo();
                InitUtil.getSubsidiaryById(ysUser.subsidiary_id_);
            }
            // 更新企业id
            StorageUtil.setItem(LocalConst.companyId, companyId);
            // 删除微信设置信息
            StorageUtil.delItem(LocalConst.wechatData);
        },
        * getPageDataByWeb({payload}, {call, put, select}) {
            const rs = yield call(ShopFetch.getPageDataByPc, {company_id_: payload});
            // 查询该企业是否需要应用装修
            if (rs.status === 1) {
                const decorateInfo = rs.data;
                const header_ = JSON.parse(decorateInfo.header_);
                const footer_ = JSON.parse(decorateInfo.footer_);
                const floors_ = JSON.parse(decorateInfo.floors_);
                const broadside_ = decorateInfo.broadside_ ? JSON.parse(decorateInfo.broadside_) : undefined;
                const background_ = JSON.parse(decorateInfo.background_);
                header_.navigation_.buttons_.forEach(item => {
                    item.newLocation = CommonUtil.getDecorateLocation(item.target_, true)
                });
                yield put({
                    type: 'saveState',
                    payload: {
                        decorateInfo: decorateInfo,
                        homeFloors: floors_,
                        pageHeader: header_,
                        pageFooter: footer_,
                        broadside_: broadside_
                    }
                });
                if (header_.style_url_ && header_.style_url_.length > 0 && header_.style_url_[0].url) {
                    //设置自定义样式表
                    CommonUtil.setCssFile(header_.style_url_[0].url)
                }
                if (Object.keys(background_).length > 0) {
                    CommonUtil.setBackground(background_)
                }

                let contentArr = [];
                floors_.forEach((item, pIndex) => {
                    if (item.content_ && item.content_.length > 0) {
                        contentArr.push({
                            content: item.content_,
                            pIndex: pIndex,
                        })
                    }
                    if (item.content_1_ && item.content_1_.length > 0) {
                        contentArr.push({
                            content: item.content_1_,
                            pIndex: pIndex,
                        })
                    }
                    if (item.content_2_ && item.content_2_.length > 0) {
                        contentArr.push({
                            content: item.content_2_,
                            pIndex: pIndex,
                        })
                    }
                });
                // 查询商品楼层
                while (contentArr.length) {
                    yield put({type: 'handleContent', payload: {...contentArr[0], companyId: payload}});
                    contentArr.splice(0, 1)
                }

                if (footer_.shop_help_ && footer_.shop_help_.help_list_) {
                    yield put({type: 'getShopHelp', payload: {help_list_: footer_.shop_help_.help_list_, companyId: payload}})
                }
            }
        },
        * handleContent({payload}, {call, put, select}) {
            // const companyId = StorageUtil.getItem(LocalConst.companyId);
            const {content, pIndex, companyId} = payload;
            let goodsFloorArr = [];
            content.forEach((item, index) => {
                if (item.type_ === 2) {
                    const {row_size_, list_style_, goods_source_type_, goods_source_val_, goods_sort_} = item.setting_;
                    if (goods_source_type_) {
                        goodsFloorArr.push({
                            content: content,
                            pIndex: pIndex,
                            index: index,
                            params: {
                                company_id_: companyId,
                                goods_num_: (row_size_ || 1) * (list_style_ || 1),// 整数相乘,不用考虑精度
                                goods_source_type_: goods_source_type_ || '',
                                goods_source_val_: goods_source_val_ || '',
                                goods_sort_: goods_sort_ || '',
                            }
                        });
                    }
                }
            });

            // 查询商品楼层
            while (goodsFloorArr.length) {
                yield put({type: 'searchListForHome', payload: goodsFloorArr[0]});
                goodsFloorArr.splice(0, 1)
            }
        },
        * searchListForHome({payload}, {call, put, select}) {
            let state = yield select(state => state.appModel);
            const {homeFloors} = state;
            const {content, pIndex, index, params} = payload;
            let {list_style_, row_size_ = 1/*YSDH-7250:商城装修商品列表旧数据兼容处理,旧数据没有设置行数，默认为2*/} = content[index].setting_;
            const rs = yield call(ShopFetch.searchListForHome, params);
            if (rs.status) {
                const pdList = rs.data.pdList;
                (pdList || []).forEach(goods => {
                    if (goods.tag_ids_) {
                        let ids = (goods.tag_ids_ + '').split(',');
                        let names = (goods.tag_names_ + '').split(',');
                        let colors = (goods.tag_colors_ + '').split(',');
                        goods.tagList = [];
                        for (let i = 0, j = ids.length; i < j; i++) {
                            goods.tagList.push({name: names[i], id: ids[i], color: colors[i]});
                        }
                    }
                    if (goods.goods_promotion_types_) {
                        goods.goodsPromotionTypes = goods.goods_promotion_types_.split(',')
                    }
                });
                let noteArr = [];
                for (let a = 0, b = row_size_; a < b; a++) {
                    const subGoodsList = (pdList || []).slice(list_style_ * a, list_style_ * (a + 1));
                    if (subGoodsList.length > 0) {
                        let subNoteArr = [];
                        for (let i = 0, j = subGoodsList.length; i < j; i++) {
                            const item = subGoodsList[i];
                            subNoteArr.push(item);
                        }
                        if (subNoteArr.length < list_style_) {
                            // 如果子note数量不足,则填满到goods_num_的数量
                            for (let x = 0, y = list_style_ - subNoteArr.length; x < y; x++) {
                                subNoteArr.push(null);
                            }
                        }
                        noteArr[a] = subNoteArr
                    }
                }
                if (homeFloors[pIndex].content_ && homeFloors[pIndex].content_.length > 0) {
                    homeFloors[pIndex].content_[index].pdList = noteArr;
                }
                if (homeFloors[pIndex].content_1_ && homeFloors[pIndex].content_1_.length > 0 && homeFloors[pIndex].content_1_[index] && homeFloors[pIndex].content_1_[index].type_ === 2) {
                    homeFloors[pIndex].content_1_[index].pdList = noteArr;
                }
                if (homeFloors[pIndex].content_2_ && homeFloors[pIndex].content_2_.length > 0 && homeFloors[pIndex].content_2_[index] && homeFloors[pIndex].content_2_[index].type_ === 2) {
                    homeFloors[pIndex].content_2_[index].pdList = noteArr;
                }
                yield put({
                    type: 'saveState',
                    payload: {
                        homeFloors: homeFloors
                    }
                })
            } else {
                console.log(rs.message)
            }
        },
        * getShopHelp({payload}, {call, put, select}) {
            let state = yield select(state => state.appModel);
            const {help_list_, companyId} = payload;
            const {pageFooter} = state;
            const rs = yield call(SystemFetch.shopHelpTypeSearchList, {company_id_: companyId, showCount: 99999999})
            if (rs.status && rs.data) {
                let newList = [];
                const shopHelpList = rs.data.pdList;
                shopHelpList.forEach(item => {
                    help_list_.forEach(help => {
                        if (item.id_ === help.id_) {
                            let obj = item;
                            obj.seq_ = help.seq_;
                            obj.hidden_ = help.hidden_;
                            newList.push(obj)
                        }
                    })
                });
                newList = newList.sort((a, b) => {
                    return a.seq_ - b.seq_
                });
                newList = newList.filter(item => !item.hidden_);
                pageFooter.shop_help_.pdList = newList;

                yield put({
                    type: 'saveState',
                    payload: {
                        pageFooter: {...pageFooter}
                    }
                })
            }
        },
        * getGoodsBaseData({payload}, {call, put, select, all}) {
            const companyId = payload.companyId;
            const [typeList, brandList, tagList, propertyList] = yield all([
                call(ShopFetch.listTypeByCompany, {companyId: companyId}),
                call(ShopFetch.listBrandByCompany, {companyId: companyId}),
                call(ShopFetch.searchTagList, {companyId: companyId}),
                call(ShopFetch.getUsePropertyTree, {companyId: companyId}),
            ]);
            const handleRemoveIsShowShop = (list) => {
                list.forEach(type => {
                    if (type.children && type.children.length > 0) {
                        type.children = handleRemoveIsShowShop(type.children)
                    }
                });
                return list.filter(item => item.fieldMap.is_display_store_);
            };

            typeList.data.children = handleRemoveIsShowShop(typeList.data.children);
            yield put({
                type: 'saveState',
                payload: {
                    typeList: typeList.data.children,
                    brandList: brandList.data.filter(item => item.is_view_shop_),
                    tagList: tagList.data,
                    propertyList: propertyList.data.children
                }
            });


        },
    },

    reducers: {
        saveState(state, action) {
            return {...state, ...action.payload};
        },
        updateIsShowNotice(state, action) {
            const {isShowNotice} = action.payload;
            console.log('isShowNotice', isShowNotice)
            return {
                ...state,
                isShowNotice
            }
        }
    },

};
